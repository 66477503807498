
<script>

function initialContactState (){
  return {
    firstName: "",
    lastName: "",
    organization: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  }
}

function initialArtistState (){
  return {
    firstName: "",
    lastName: "",
    preferredName: "",
    gender: "",
    ethnicity: "",
    birthMonth: "",
    birthDay: "",
    birthYear: "",
    birthDate: "",
    age: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    instagramUsername: "",
    website: "",
    exhibitionHistory: "",
    residencies: "",
    awards: "",
    galleryRepresentation: ""
  }
}

function initialWorkState() {
  return{
    title: "",
    date: "",
    size: "",
    price: "",
    category: "",
    description: "",
    medium: "",
    signed: "",
    loanOrSale: "",
    mainImage: [],
    alternateViews: [],
    documents: [],
    specialInstallationInstructions: "",
    specialCareInstructions: "",
    specialCratePacking: "",
    packingSizeDescription: "",
    packedDimensions: ""
  }
}

export default {
  name: 'App',
  components: {},
  data() {
    return {
      page: 0,
      states: [
        { text: 'Alabama',        value: 'AL' }, { text: 'Alaska',        value: 'AK' }, { text: 'Arizona',        value: 'AZ' },
        { text: 'Arkansas',       value: 'AR' }, { text: 'California',    value: 'CA' }, { text: 'Colorado',       value: 'CO' }, 
        { text: 'Connecticut',    value: 'CT' }, { text: 'Delaware',      value: 'DE' }, { text: 'Florida',        value: 'FL' }, 
        { text: 'Georgia',        value: 'GA' }, { text: 'Hawaii',        value: 'HI' }, { text: 'Idaho',          value: 'ID' }, 
        { text: 'Illinois',       value: 'IL' }, { text: 'Indiana',       value: 'IN' }, { text: 'Iowa',           value: 'IA' }, 
        { text: 'Kansas',         value: 'KS' }, { text: 'Kentucky',      value: 'KY' }, { text: 'Louisiana',      value: 'LA' }, 
        { text: 'Maine',          value: 'ME' }, { text: 'Maryland',      value: 'MD' }, { text: 'Massachusetts',  value: 'MA' }, 
        { text: 'Michigan',       value: 'MI' }, { text: 'Minnesota',     value: 'MN' }, { text: 'Mississippi',    value: 'MS' }, 
        { text: 'Missouri',       value: 'MO' }, { text: 'Montana',       value: 'MT' }, { text: 'Nebraska',       value: 'NE' }, 
        { text: 'Nevada',         value: 'NV' }, { text: 'New Hampshire', value: 'NH' }, { text: 'New Jersey',     value: 'NJ' }, 
        { text: 'New Mexico',     value: 'NM' }, { text: 'New York',      value: 'NY' }, { text: 'North Carolina', value: 'NC' }, 
        { text: 'North Dakota',   value: 'ND' }, { text: 'Ohio',          value: 'OH' }, { text: 'Oklahoma',       value: 'OK' }, 
        { text: 'Oregon',         value: 'OR' }, { text: 'Pennsylvania',  value: 'PA' }, { text: 'Rhode Island',   value: 'RI' }, 
        { text: 'South Carolina', value: 'SC' }, { text: 'South Dakota',  value: 'SD' }, { text: 'Tennessee',      value: 'TN' }, 
        { text: 'Texas',          value: 'TX' }, { text: 'Utah',          value: 'UT' }, { text: 'Vermont',        value: 'VT' }, 
        { text: 'Virginia',       value: 'VT' }, { text: 'Washington',    value: 'WA' }, { text: 'West Virginia',  value: 'WV' }, 
        { text: 'Wisconsin',      value: 'WI' }, { text: 'Wyoming',       value: 'WY' }
      ],
      ethnicities: [
        'Black or African American/African/Caribbean',
        'Asian',
        'Latino/ Hispanic American',
        'Native American',
        'Pacific Islander',
        'White'
      ],
      categories: [
        'Painting',
        'Drawing',
        'Sculpture',
        'Assemblage',
        'Photography',
        'Installation',
        'Film',
        'Digital Art',
        'Print/ Work on Paper'
      ],
      submit: {
        contact: initialContactState(),
        artist: initialArtistState(),
        works: []
      },
      work: initialWorkState(),
      workIndex: null,
      failedSubmit: false,
    }
  },
  computed: {
    headerText(){
      if(this.page == 1){ return "Artist Information" }
      else if(this.page == 2){ return "Artwork submission form" }
      else{ return "" }
    },
    nextText(){
      if(this.page == 0){ return "Next - Artist Bio" }
      else if(this.page == 1){ return "Next: Upload artwork" }
      else if(this.page == 2){ return "Next step" }
      else{ return "" }
    },
    backText(){
      if(this.page == 1){ return "Go Back" }
      else{ return "" }
    },
    showNextButton(){
      return this.page == 0 || this.page == 1 || this.page == 2
    },
    showBackButton(){
      return this.page == 1
    },
    showSubmitButton(){
      return this.page == 3
    }
  },
  methods: {
    nextPage(){
      if(this.page < 4 && this.canSubmit(this.page)){
        if(this.page == 2) { this.saveWork(); }
        this.page += 1;
        this.updateArtistBday();
        window.scrollTo(0, 0);
      }
    },
    prevPage(){
      if(this.page > 0){
        this.page -= 1;
        this.updateArtistBday();
        window.scrollTo(0, 0);
      }
    },
    changePage(x){
      this.page = x;
      this.updateArtistBday();
      window.scrollTo(0, 0);
    },
    updateArtistBday(){
      const date = [this.submit.artist.birthYear, this.submit.artist.birthMonth, this.submit.artist.birthDay];
      this.submit.artist.birthDate = date.join('-');
    },
    saveWork(){
      if(this.workIndex == null){
        const workCopy = {};
        Object.assign(workCopy, this.work);
        this.submit.works.push(workCopy);
        console.log(this.submit.works);
      }
      else{
        const workCopy = {};
        Object.assign(workCopy, this.work);
        Object.assign(this.submit.works[this.workIndex], workCopy);
      }
      console.log("Saved: " + this.work)
    },
    editWork(index){
      console.log("editWork: " + index);
      if(index != null){
        Object.assign(this.$data.work, initialWorkState());
        Object.assign(this.$data.work, this.$data.submit.works[index])
        console.log(this.submit.works[index]);
        console.log(this.work)
      }
      else{
        Object.assign(this.$data.work, initialWorkState());
      }
      this.workIndex = index;
      this.changePage(2);
    },
    deleteWork(index){
      this.submit.works.splice(index, 1);
    },
    submitForm(){
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "apikey": '4d0676b4-72d6-11eb-8e05-df0d4d2768de' },
        body: JSON.stringify(this.submit)
      };
      fetch("https://api.arternal.com/public_submission", requestOptions)
      .then()
      this.nextPage();
    },
    readURL(input, string){
      // console.log("readURL called");
      // console.log(input);
      if (input.files && input.files[0] && string == 'mainImage') {
        // console.log("inside the if");
        const reader = new FileReader();
        const vm = this;
        reader.onload = function () {
          // console.log("this:" + this);
          // console.log("reader.result: " + reader.result)
          const base64 = reader.result.split(',');
          // console.log("new base64: " + base64[1] )
          vm.work.mainImage[0] = { name: input.files[0].name, header: base64[0], src: base64[1] };
        }
        reader.readAsDataURL(input.files[0]);
      }
      else if (input.files){
        const vm = this;
        input.files.forEach(element => {
          const reader = new FileReader();
          const img = document.createElement('img');
          reader.onload = function () {
            // console.log("this:" + this);
            img.src = reader.result;
            // console.log("reader.result: " + reader.result)
            const base64 = reader.result.split(',');
            // console.log("new base64: " + base64[1] )
            vm.work[string].push( { name: element.name, header: base64[0], src: base64[1] } );
          }
          // console.log("forEach")
          reader.readAsDataURL(element);
        });
      }
    },
    deleteImage(index, string){
      this.work[string].splice(index, 1);
    },
    displayImage(work){
      if(work?.mainImage[0]?.header && work.mainImage[0].src){
        return work.mainImage[0].header + ',' + work.mainImage[0].src;
      }
      return require("./assets/image-frame.png");
    },
    refresh(){
      window.location.reload();
    },
    visitWebsite(){
      window.location.replace("https://www.manacontemporary.com/");
    },
    canSubmit(page){
      if(page == 0){
        for(const key of Object.keys(this.submit.contact)){
          if(!this.submit.contact[key]){
            this.failedSubmit = true;
            return false;
          }
        }
      }
      else if(page == 1){
        for(const key of Object.keys(this.submit.artist)){
          if(!this.submit.artist[key] && !(key == "exhibitionHistory" || key == "residencies" || key == "awards" || key == "galleryRepresentation")){
            this.failedSubmit = true;
            return false;
          }
        }
      }
      else if(page == 2){
        for(const key of Object.keys(this.work)){
          if(!this.work[key] && !(key == "alternativeViews" || key == "documents")){
            this.failedSubmit = true;
            return false;
          }
        }
      }
      this.failedSubmit = false;
      return true;
    }
  }
}
</script>

<template>
  <div id="app">
    <form>
      <div id="head">
        <img alt="Mana Contemporary logo" src="./assets/MClogo.png" class="logo">
        <span class="thick">{{headerText}}</span>
      </div>
      <div id="bod">
        <div v-if="page == 0" class="formClass">
          <div style="font-weight:600; font-size:20px">SITES OF PROTEST — Artwork Submission</div>
          <div style="font-style:italic; margin-bottom:20px">Please enter the main point of contact for the artwork submission below</div>
          <div class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active"><a>Artwork submission form - Contact Info</a></li>
            </ul>
          </div>
          <span class="kindlyClass">Kindly fill in all to the best of your abilites. “N/A” is acceptable for fields you do not have info for. <br> 
          If your artwork is selected, the information you share with us about you, your artwork, and your career will be used for promotional purposes. We recommend you share with us as much as you can about you, your career, and your artwork</span>

          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <label class="label">First Name</label>
                <div class="control">
                  <input class="input" :class='{badValue : !submit.contact.firstName && failedSubmit}' v-model="submit.contact.firstName" type="text" placeholder="First name">
                </div>
              </div>
              <div class="field">
                <label class="label">Last Name</label>
                <div class="control">
                  <input class="input" :class='{badValue : !submit.contact.lastName && failedSubmit}' v-model="submit.contact.lastName" type="text" placeholder="Last name">
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Organization</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.contact.organization && failedSubmit}' v-model="submit.contact.organization" type="text" placeholder="Organization">
            </div>
          </div>

          <div class="field">
            <label class="label">Phone Number</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.contact.phoneNumber && failedSubmit}' v-model="submit.contact.phoneNumber" type="text" placeholder="Phone number">
            </div>
          </div>

          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.contact.email && failedSubmit}' v-model="submit.contact.email" type="email" placeholder="Email">
            </div>
          </div>

          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.contact.address && failedSubmit}' v-model="submit.contact.address" type="text" placeholder="Address">
            </div>
          </div>

          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.contact.city && failedSubmit}' v-model="submit.contact.city" type="text" placeholder="City">
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <label class="label">State</label>
                <div class="control">
                  <div class="select">
                    <select :class='{badValue : !submit.contact.state && failedSubmit}' v-model="submit.contact.state">
                      <option disabled value="">Select state</option>
                      <option v-for="option in states" v-bind:key="option.id" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Zip Code</label>
                <div class="control">
                  <input class="input" :class='{badValue : !submit.contact.zipCode && failedSubmit}' v-model="submit.contact.zipCode" type="text" placeholder="Zip Code">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="page == 1"  class="formClass">
          <div class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a @click="changePage(0)">Contact Info</a></li>
              <li class="is-active"><a>Artist Bio</a></li>
            </ul>
          </div>
          <span class="kindlyClass">Kindly fill in all to the best of your abilites. “N/A” is acceptable for fields you do not have info for. <br> 
          If your artwork is selected, the information you share with us about you, your artwork, and your career will be used for promotional purposes. We recommend you share with us as much as you can about you, your career, and your artwork</span>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <label class="label">First Name</label>
                <div class="control">
                  <input class="input" v-model="submit.artist.firstName" :class='{badValue : !submit.artist.firstName && failedSubmit}' type="text" placeholder="Artist Legal First Name">
                </div>
              </div>
              <div class="field">
                <label class="label">Last Name</label>
                <div class="control">
                  <input class="input" v-model="submit.artist.lastName" :class='{badValue : !submit.artist.lastName && failedSubmit}' type="text" placeholder="Artist Legal Last Name">
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Name artist would like to be addressed as</label>
            <div class="control">
              <input class="input" v-model="submit.artist.preferredName" :class='{badValue : !submit.artist.preferredName && failedSubmit}' type="text" placeholder="Artist Preferred Name">
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field" style="width:25%">
                <label class="label">Gender</label>
                <div class="control radioControl" :class='{badValue : !submit.artist.gender && failedSubmit}'>
                  <label class="radio">
                    <input type="radio" v-model="submit.artist.gender" value="Male">
                    Male
                  </label>
                  <label class="radio" style="margin-left:30px">
                    <input type="radio" v-model="submit.artist.gender" value="Female">
                    Female
                  </label>
                </div>
              </div>
              <div class="field" style="width:75%;margin-top:25px">
                <label class="label"></label>
                <div class="control">
                  <input class="input" v-model="submit.artist.gender" :class='{badValue : !submit.artist.gender && failedSubmit}' type="text" placeholder="Enter other">
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Identify ethnicity as</label>
            <div class="control">
              <div class="select">
                <select :class='{badValue : !submit.artist.ethnicity && failedSubmit}' v-model="submit.artist.ethnicity">
                  <option disabled value="">Select ethnicity</option>
                  <option v-for="option in ethnicities" v-bind:key="option.id" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Age</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.age && failedSubmit}' v-model="submit.artist.age" type="number" placeholder="Age">
            </div>
          </div>

          <div class="field is-horizontal" style="width:60%">
            <div class="field-body">
              <div class="field" style="width:30%">
                <label class="label">Birth Date</label>
                <div class="control">
                  <input class="input" :class='{badValue : !submit.artist.birthMonth && failedSubmit}' v-model="submit.artist.birthMonth" type="number" placeholder="MM">
                </div>
              </div>
              <div class="field" style="width:30%;margin-top:32px">
                <div class="control">
                  <input class="input" :class='{badValue : !submit.artist.birthDay && failedSubmit}' v-model="submit.artist.birthDay" type="number" placeholder="DD">
                </div>
              </div>
              <div class="field" style="width:40%;margin-top:32px">
                <div class="control">
                  <input class="input" :class='{badValue : !submit.artist.birthYear && failedSubmit}' v-model="submit.artist.birthYear" type="number" placeholder="YYYY">
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Phone Number</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.phoneNumber && failedSubmit}' v-model="submit.artist.phoneNumber" type="text" placeholder="Phone number">
            </div>
          </div>

          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.email && failedSubmit}' v-model="submit.artist.email" type="email" placeholder="Email">
            </div>
          </div>

          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.address && failedSubmit}' v-model="submit.artist.address" type="text" placeholder="Address">
            </div>
          </div>

          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.city && failedSubmit}' v-model="submit.artist.city" type="text" placeholder="City">
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <label class="label">State</label>
                <div class="control">
                  <div class="select">
                    <select :class='{badValue : !submit.artist.state && failedSubmit}' v-model="submit.artist.state">
                      <option disabled value="">Select state</option>
                      <option v-for="option in states" v-bind:key="option.id" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Zip Code</label>
                <div class="control">
                  <input class="input" :class='{badValue : !submit.artist.zipCode && failedSubmit}' v-model="submit.artist.zipCode" type="text" placeholder="Zip Code">
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Instagram username</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.instagramUsername && failedSubmit}' v-model="submit.artist.instagramUsername" type="text" placeholder="@username">
            </div>
          </div>

          <div class="field">
            <label class="label">Website</label>
            <div class="control">
              <input class="input" :class='{badValue : !submit.artist.website && failedSubmit}' v-model="submit.artist.website" type="text" placeholder="http://website.com">
            </div>
          </div>

          <div class="field">
            <label class="label">Exhibition History</label>
            <div class="control">
              <textarea class="textarea" v-model="submit.artist.exhibitionHistory" placeholder="Exhibition History"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Residencies</label>
            <div class="control">
              <textarea class="textarea" v-model="submit.artist.residencies" placeholder="Residencies"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Awards</label>
            <div class="control">
              <textarea class="textarea" v-model="submit.artist.awards" placeholder="Awards"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Gallery Representation (Please mark which ones are current)</label>
            <div class="control">
              <textarea class="textarea" v-model="submit.artist.galleryRepresentation" placeholder="Gallery Representation"></textarea>
            </div>
          </div>
        </div>
        <div v-if="page == 2" class="formClass">
          <div class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a @click="changePage(0)">Contact Info</a></li>
              <li><a @click="changePage(1)">Artist Bio</a></li>
              <li class="is-active"><a>Add artwork</a></li>
            </ul>
          </div>
       <span class="kindlyClass">Kindly fill in all to the best of your abilites. “N/A” is acceptable for fields you do not have info for. <br> 
          If your artwork is selected, the information you share with us about you, your artwork, and your career will be used for promotional purposes. We recommend you share with us as much as you can about you, your career, and your artwork</span>
    <div class="field">
            <label class="label">Title</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.title && failedSubmit}' v-model="work.title" type="text" placeholder="Title">
            </div>
          </div>
          <div class="field">
            <label class="label">Year</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.date && failedSubmit}' v-model="work.date" type="text" placeholder="Date">
            </div>
          </div>
          <div class="field">
            <label class="label">Dimensions (size in inches)</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.size && failedSubmit}' v-model="work.size" type="text" placeholder="h x l x d">
            </div>
          </div>
          <div class="field">
            <label class="label">Price</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.price && failedSubmit}' v-model="work.price" type="text" placeholder="Price">
            </div>
          </div>
          <div class="field">
            <label class="label">For loan or sale?</label>
            <div class="control radioControl" :class='{badValue : !work.loanOrSale && failedSubmit}'>
              <label class="radio">
                <input v-model="work.loanOrSale" type="radio" value="Loan">
                Loan
              </label>
              <label class="radio">
                <input v-model="work.loanOrSale" type="radio" value="For Sale">
                For Sale
              </label>
            </div>
          </div>
          <div class="field">
            <label class="label">Category</label>
            <div class="control">
              <div class="select">
                <select :class='{badValue : !work.category && failedSubmit}' v-model="work.category">
                  <option disabled value="">Select category</option>
                  <option v-for="option in categories" v-bind:key="option.id" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Description of work</label>
            <div class="control">
              <textarea class="textarea" :class='{badValue : !work.description && failedSubmit}' v-model="work.description" placeholder="Description"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Medium</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.medium && failedSubmit}' v-model="work.medium" type="text" placeholder="Medium">
            </div>
          </div>
          <div class="field">
            <label class="label">Signed</label>
            <div class="control radioControl" :class='{badValue : !work.signed && failedSubmit}'>
              <label class="radio">
                <input v-model="work.signed" type="radio" value="Signed">
                Yes
              </label>
              <label class="radio">
                <input v-model="work.signed" type="radio" value="Not Signed">
                No
              </label>
            </div>
          </div>
          <div class="field">
            <label class="label">Upload main artwork image</label>
            <div class="control">
              <div class="dropbox" :class='{badValue : !work.mainImage.length && failedSubmit}'>
                <p v-if="!work.mainImage.length" class="imageWarning">Please ensure the image is the best representation of the artwork<br>i.e. close up, not blurry, nothing hindering the ability to assess the artwork adequately</p>
                <input type="file" @change="readURL($event.target, 'mainImage'); fileCount = $event.target.files.length"
                  accept="image/*" class="input-file">
                <p v-if="!work.mainImage.length" class="dropboxText">Drop files here or click to upload</p>
                <div class="imageContainer" v-for="(image, index) in work.mainImage" :key="index">
                  <div class="imageName">{{image.name}}</div>
                  <img class="uploadImage" :src="image.header + ',' + image.src">
                  <div class="deleteImage" @click="deleteImage(index, 'mainImage')">
                    X
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Upload alternate views or photos of the packed work</label>
            <div class="control">
              <div class="dropbox">
                <input type="file" multiple @change="readURL($event.target, 'alternateViews'); fileCount = $event.target.files.length"
                  accept="image/*" class="input-file">
                <p v-if="!work.alternateViews.length" class="dropboxText">Drop files here or click to upload</p>
                <div class="imageContainer" v-for="(image, index) in work.alternateViews" :key="index">
                  <div class="imageName">{{image.name}}</div>
                  <img class="uploadImage" :src="image.header + ',' + image.src">
                  <div class="deleteImage" @click="deleteImage(index, 'alternateViews')">X</div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Upload supporting documents (Certificate of authenticity)</label>
            <div class="control">
              <div class="dropbox">
                <input type="file" multiple @change="readURL($event.target, 'documents'); fileCount = $event.target.files.length"
                  class="input-file">
                <p v-if="!work.documents.length" class="dropboxText">Drop files here or click to upload</p>
                <div class="imageContainer" v-for="(image, index) in work.documents" :key="index">
                  <div class="imageName">{{image.name}}</div>
                  <img class="uploadImage" src="./assets/file-document-outline.png">
                  <div class="deleteImage" @click="deleteImage(index, 'documents')">X</div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Are there special installation instructions?</label>
            <div class="control">
              <textarea class="textarea" :class='{badValue : !work.specialInstallationInstructions && failedSubmit}' v-model="work.specialInstallationInstructions" placeholder="Enter notes"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Are there special care instructions?</label>
            <div class="control">
              <textarea class="textarea" :class='{badValue : !work.specialCareInstructions && failedSubmit}' v-model="work.specialCareInstructions" placeholder="Enter notes"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Is there a special crate of packing with the artwork?</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.specialCratePacking && failedSubmit}' v-model="work.specialCratePacking" type="text" placeholder="">
            </div>
          </div>
          <div class="field">
            <label class="label">Enter the size and description of the packing</label>
            <div class="control">
              <textarea class="textarea" :class='{badValue : !work.packingSizeDescription && failedSubmit}' v-model="work.packingSizeDescription" placeholder="Enter notes"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Dimensions of the packed work (size in inches)</label>
            <div class="control">
              <input class="input" :class='{badValue : !work.packedDimensions && failedSubmit}' v-model="work.packedDimensions" type="text" placeholder="h x l x d">
            </div>
          </div>
        </div>
        <div v-if="page == 3">
          <div class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a @click="changePage(0)">Contact Info</a></li>
              <li><a @click="changePage(1)">Artist Bio</a></li>
              <li class="is-active"><a>Add artwork</a></li>
            </ul>
          </div>
        <span class="kindlyClass">Kindly fill in all to the best of your abilites. “N/A” is acceptable for fields you do not have info for. <br> 
          If your artwork is selected, the information you share with us about you, your artwork, and your career will be used for promotional purposes. We recommend you share with us as much as you can about you, your career, and your artwork</span>
        <div class="cardContainer">
            <div class="card" v-for="(work, index) in this.submit.works" :key="index">
              <div class="card-content">
                <div class="content">
                  <img class="cardImage" :src="displayImage(work)">
                </div>
              </div>
              <footer class="card-footer">
                <a href="#" class="card-footer-item" @click="editWork(index)">Edit</a>
                <a href="#" class="card-footer-item" v-show="index" @click="deleteWork(index)">Delete</a>
              </footer>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <a @click="editWork()">
                    <div class="addWork1">+</div>
                    <div class="addWork2">Add additional artwork</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="page == 4" class="lastPage">
          <span>Thank you for submitting to Mana Contemporary</span>
          <div id="submitButton" class="buttonClass" @click="refresh()">Submit another</div>
          <div id="backButton" class="buttonClass" @click="visitWebsite()">View our website</div>
        </div>
      </div>
      <div id="foot">
        <div id="backButton" class="buttonClass" v-if="showBackButton" @click="prevPage">{{backText}}</div>
        <div id="nextButton" class="buttonClass" v-if="showNextButton" @click="nextPage">{{nextText}}</div>
        <div id="submitButton" class="buttonClass" v-if="showSubmitButton" @click="submitForm">Submit now</div>
      </div>
      <div id="requiredFieldText" v-if="failedSubmit">*Missing required fields</div>
    </form>
  </div>
</template>

<style lang="scss">
  #app {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
  }
  form {
    text-align: left;
    display: block;
    width: 1200px;
    box-shadow: 5px 5px 2px lightGray;
    margin: auto;
    margin-top: 60px;
    padding: 70px 150px;
    box-sizing: border-box;
  }
  #head, #bod{
    display: block;
    margin-bottom: 30px;
  }
  #foot{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  #foot > :only-child{
    margin-left: auto;
    margin-right: auto;
  }
  .logo{
    display: inline-block;
  }
  .blue{
    color: #2F80ED;
    font-size: 20px;
  }
  .thick{
    font-weight: 600;
    font-size: 20px;
  }
  .buttonClass{
    display: inline-block;
    text-align: center;
    padding: 10px 36px;
    border-radius: 4px;
    cursor: pointer;
  }
  .buttonClass:hover{
    transition: 0.2s;
    transform: scale(1.03);
  }
  #backButton{
    background: #EDEDED;
    color: #272727;
  }
  #nextButton{
    background: #333333;
    color: white;
    font-weight: 700;
  }
  #submitButton{
    background: #2F80ED;
    color: white;
    font-weight: 700;
  }
  .select, .select select{ 
    width: 100%;
  }
  .formClass{
    width: 80%;
  }
  .formClass>.field{
    margin-top: 30px;
  }
  .breadcrumb li{
    font-size: 20px;
  }
  .breadcrumb li.is-active{
    font-weight: 600;
  }
  .dropbox {
    outline: 3px dashed #2F80ED; /* the dash box */
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    text-align: center;
  }

  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top:0;
    bottom:0;
    left:0;
    right:0;
  }

  .dropbox:hover {
    background: #e3f2ff;
  }

  .dropboxText {
    font-size: 1.2em;
    text-align: center;
    padding: 80px 0;
  }

  .cardContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .card{
    width: 200px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  .cardImage{
    max-height:157px;
    max-width: 200px;
  }
  .card-content{
    padding: 0 !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
  }
  .imageContainer{
    display: inline-block;
    width: 200px;
    height: 200px;
    background: lightgray;
    border: 1px solid black;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .uploadImage{
    max-width: 100%;
    max-height: 100%;
    display: block;
    transform: translate(-50%, -50%);
    position: relative;
    left: 50%;
    top: 50%;
  }
  .imageName{
    display: block;
    font-size: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    background: whitesmoke;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .deleteImage{
    display: none;
    font-size: 100px;
    font-weight: 1000;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .imageContainer:hover{
    filter: brightness(65%);
  }
  .imageContainer:hover .deleteImage{
    display: block;
  }
  .addWork1{
    font-size: 30px;
    font-weight: 600;
  }
  .addWork2{
    font-size: 18px;
    font-weight: 600;
    padding: 0 20px;
  }
  .select select{
    padding-left: 10px;
  }
  .textarea, .input{
    padding-left: 10px;
  }
  .lastPage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .lastPage .buttonClass{
    margin-top: 30px;
  }
  .badValue{
    background-color: #ffcccc !important;
  }
  .radioControl{
    border: 1px solid #dbdbdb;
    padding: 8px;
    border-radius: 4px;
  }
  .radioControl:hover{
    border-color: #b5b5b5
  }
  .kindlyClass{
    font-size: 15px;
    font-style: italic;
  }
  .imageWarning{
    position: absolute;
    color: red;
    font-size: 15px;
    font-style: italic;
    text-align: left;
  }
  #requiredFieldText{
    color: red;
    text-align: center;
  }
</style>
